<template>
  <layout-base>
    <default-header />

    <v-layout class="overflow-inherit!">
      <v-main>
        <div class="space-header">
          <v-container class="hidden md:block py-0">
            <div class="py-2">
              <router-link
                v-for="link in navLinks"
                :key="link.text"
                :to="link.to"
                class="nav-link mr-5"
              >
                {{ link.text }}
              </router-link>
            </div>
            <v-divider />
          </v-container>
        </div>
        <slot />
      </v-main>
    </v-layout>

    <default-footer />
    <mobile-nav class="md:hidden" />
  </layout-base>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import LayoutBase from "@/components/layout/modules/LayoutBase.vue";
import DefaultHeader from "~~/components/layout/headers/DefaultHeader.vue";
import DefaultFooter from "~~/components/layout/footers/DefaultFooter.vue";
import MobileNav from "~~/components/layout/footers/MobileNav.vue";

export default defineComponent({
  components: {
    LayoutBase,
    DefaultHeader,
    DefaultFooter,
    MobileNav,
  },
  data: () => ({
    navLinks: [
      { text: "Products", to: { name: "products" } },
      { text: "Services", to: { name: "services" } },
      { text: "Events", to: { name: "events" } },
      { text: "Recipes", to: { name: "recipes" } },
      { text: "Stores", to: { name: "shops" } },
      //
    ],
  }),
});
</script>

<style lang="scss">
.space-header {
  margin-top: 50px;
  @include breakpoints-up(md) {
    margin-top: 64px; // default v-app-bar height for DefaultHeader
  }
}
.nav-link {
  font-size: 0.8rem;
  font-weight: 600;
}
</style>
